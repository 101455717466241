<template>
  <div class="d-flex flex-wrap">
    <div class="col-12 col-md-6 position-relative">
      <div class="skeleton-texts-banner-large">
        <b-skeleton height="20px" width="170px" class="mb-4"></b-skeleton>
        <b-skeleton
          height="50px"
          width="70%"
          class="d-flex d-xl-none title-1"
        ></b-skeleton>
        <b-skeleton height="50px" width="40%" class="mb-5 title-2"></b-skeleton>
        <b-skeleton height="50px" width="200px" class=""></b-skeleton>
      </div>
    </div>
    <div class="col-12 col-md-6 skeleton-img-banner-home">
      <b-skeleton-img class=""></b-skeleton-img>
    </div>
  </div>
</template>
<script>
import { BSkeletonImg, BSkeleton } from 'bootstrap-vue'
export default {
  components: { BSkeletonImg, BSkeleton },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.outfitsVitrina && _vm.outfitsVitrina.outfits.length > 0)?_c('div',{key:_vm.$route.name,staticClass:"container-carousel-categorys"},[_c('div',{staticClass:"ps-4"},[_c('h2',{staticClass:"title-carousel"},[_vm._v(" "+_vm._s(_vm.outfitsVitrina.titulo_vitrina)+" "+_vm._s(_vm.outfitsVitrina.subtitulo_vitrina)+" ")])]),_c('carousel',{key:_vm.innerWidth,staticClass:"col-12 mx-0 position-relative carousel-products-content",attrs:{"id":"carousel-banner-category","dots":true,"lazy-load":true,"nav":false,"responsive":{
      0: { items: 1, nav: false, stagePadding: 60 },
      704: { items: 3, nav: false, stagePadding: 60 },
      1030: { items: 4, nav: false, stagePadding: 50 },
      1200: { items: 5, nav: false, stagePadding: 30 },
    }}},[_c('template',{slot:"prev"},[_c('span',{staticClass:"prev nav-button d-none d-md-block"},[_c('img',{attrs:{"src":require("@/assets/icons/left-carousel.svg"),"alt":"left arrow carousel"}})])]),_c('template',{slot:"next"},[_c('span',{staticClass:"next nav-button d-none d-md-block"},[_c('img',{attrs:{"src":require("@/assets/icons/right-carousel.svg"),"alt":"right arrow carousel"}})])]),_vm._l((_vm.outfitsVitrina.outfits),function(outfitVitrina,index){return _c('div',{key:index,staticClass:"item-category-home"},[_c('router-link',{attrs:{"to":{
          name: 'OutfitDetail',
          params: {
            category: outfitVitrina.campania_slug,
            id: outfitVitrina.slug,
            nameCreative: `home-${outfitVitrina.slug}`,
            slot: `Home-primary-outfits`,
            idCampaign: `${outfitVitrina.campania_slug}`,
            name: `${outfitVitrina.nombre}`,
          },
        }}},[_c('div',{staticClass:"container-img-carousel-category"},[_c('img',{attrs:{"src":outfitVitrina.img_portada,"alt":outfitVitrina.nombre}})]),_c('div',{staticClass:"item-category"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(outfitVitrina.nombre)+" ")])])])],1)})],2),_c('div',{staticClass:"text-center"},[_c('a',{attrs:{"href":_vm.outfitsVitrina.url}},[_c('button',{staticClass:"secondary-button"},[_vm._v(" Ver todos los outfits ")])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
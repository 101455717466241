var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 d-block d-md-flex mt-5 pb-5 pb-md-0",style:('background-color:' + _vm.bgVitrina),attrs:{"id":'vitrina-banner-' + _vm.slug}},[_c('div',{staticClass:"container-max-width d-flex flex-wrap w-100"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"carousel-products-banner"},[_c('div',[_c('h2',{staticClass:"title-carousel"},[_vm._v(_vm._s(_vm.title)+" /")])]),_c('carousel',{key:_vm.innerWidth,staticClass:"col-12 px-0 mx-0 position-relative",attrs:{"id":'carousel-banner-' + _vm.slug,"dots":true,"nav":false,"dots-each":true,"responsive":{
            0: { items: 1, nav: false, stagePadding: 30 },
            704: { items: 2, nav: false, stagePadding: 60 },
            1030: { items: _vm.itemsXfila, nav: false, stagePadding: 80 },
          }}},[_c('template',{slot:"prev"},[_c('span',{staticClass:"prev nav-button d-none d-md-block",on:{"click":function($event){_vm.positionDot <= 0
                  ? (_vm.positionDot = _vm.products.length - 1)
                  : (_vm.positionDot -= 1)}}},[_c('img',{attrs:{"src":require("@/assets/icons/left-carousel.svg"),"alt":"left arrow carousel"}})])]),_c('template',{slot:"next"},[_c('span',{staticClass:"next nav-button d-none d-md-block",on:{"click":function($event){_vm.positionDot >= _vm.products.length - 1
                  ? (_vm.positionDot = 0)
                  : (_vm.positionDot += 1)}}},[_c('img',{attrs:{"src":require("@/assets/icons/right-carousel.svg"),"alt":"right arrow carousel"}})])]),_c('router-link',{staticClass:"d-flex w-100 pe-3",attrs:{"to":{ name: 'TiendaCategory', params: { category: _vm.slug } }}},[_c('div',{staticClass:"bg-card-img",style:('background-image: url(' + _vm.imgURL + ');')})]),_vm._l((_vm.products),function(product,index){return _c('div',{key:index,staticClass:"item-product-carousel",attrs:{"to":{ name: '' }},on:{"click":function($event){return _vm.clicProduct(_vm.productsAnalytics, product.producto_sku)}}},[_c('card-product-hover',{attrs:{"id":"box-sizes-carousel-banner","product":product}})],1)}),_c('router-link',{staticClass:"w-100",attrs:{"to":{ name: 'TiendaCategory', params: { category: _vm.slug } }}},[_c('div',{staticClass:"last-card-carousel"},[_c('p',[_vm._v(" Descubre aquí más "),_c('br'),_vm._v(" productos similares ")])])])],2)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }